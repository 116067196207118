
.infoBox {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 80px;
  border-radius: 10px;
  padding: 20px;
  min-width: 250px;
  margin: 5px;
}
.infoTitle {
  font-family: Arial, Helvetica, sans-serif;
  text-align: right; 
  color: white;
}
.infoValue {
  text-align: right;
  color: white;
}

.contentBlock {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  min-height: 200px;
  min-width: 400px;
}

.boxShadow {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
}
  
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);  
}  
/* 
table th:first-child{
  border-radius:10px 0 0 0;
}

table th:last-child{
  border-radius:0 10px 0 0;
} */

.styled-table thead tr {   
  border-radius:10px 0 10px 0;
  background-color: #009879;
  color: #ffffff;
  text-align: left;  
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
  text-align: left;  
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;  
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
 

.box {   
  margin-bottom: 20px;
  border-radius: 15px;
}

.box p {
  line-height: 100px;
  font-size: 2em;
  color: white;
}

/* Gradient 1 */
#g1 {
  background: linear-gradient(90deg, #69b7eb, #b3dbd3, #f4d6db);
}

/* Gradient 2 */
#g2 {
  background: linear-gradient(90deg, #cfecd0, #ffc5ca);
}

/* Gradient 3 */
#g3 {
  background: linear-gradient(90deg, #f598a8, #f6edb2);
}

/* Gradient 4 */
#g4 {
  background: linear-gradient(90deg, #ee5c87, #f1a4b5, #d587b3);
}

/* Gradient 5 */
#g5 {
  background: linear-gradient(90deg, #b9deed, #efefef);
}

/* Gradient 6 */
#g6 {
  background: linear-gradient(90deg, #aea4e3, #d3ffe8);
}

/* Gradient 7 */
#g7 {
  background: linear-gradient(90deg, #faf0cd, #fab397);
}

/* Gradient 8 */
#g8 {
  background: linear-gradient(90deg, #cfecd0, #a0cea7, #9ec0db);
}